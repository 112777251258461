import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { ICONS } from '../assets';
import { CommonStyle } from '../utils/commonStyle';
import styled from '@emotion/styled';
import { COLORS } from '../utils/theme';
import CommonButton from '../components/CommonButton';

const StyledProgressView = styled(CircularProgress)({
    marginLeft: 8
});

const AlertDialog = ({ isOpen, isClose, title, description, handleSubmit, isCancel, autoFocus, loading, disabled }) => {
    const AgreeButton = styled(Button)({
        color: COLORS.white,
        borderRadius: '0.425rem',
        background: COLORS.primary.success,
        padding: '4px 8px',
        fontWeight: 500,
        border: `1px solid ${COLORS.primary.success}`,
        '&: hover': {
            background: COLORS.hover.success,
            color: 'white'
        }
    });

    const DisAgreeButton = styled(Button)({
        color: COLORS.white,
        border: `1px solid ${COLORS.gray500}`,
        borderRadius: '0.425rem',
        background: COLORS.gray500,
        fontWeight: 500,
        padding: '4px 8px',
        '&: hover': {
            background: COLORS.hover.gray,
            color: 'white'
        }
    });

    const Title = styled(DialogTitle)({
        padding: '16px 24px 8px 24px',
        fontSize: 24
    });

    return (
        <div>
            <Dialog
                PaperProps={{ sx: { borderRadius: '0.425rem' } }}
                open={isOpen}
                onClose={isClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Title id="alert-dialog-title">{title || ''}</Title>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{description || ''}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {isCancel && <DisAgreeButton onClick={isClose}> {isCancel}</DisAgreeButton>}
                    {handleSubmit && (
                        <AgreeButton onClick={handleSubmit} {...{ autoFocus, disabled }}>
                            Yes
                            {loading ? <StyledProgressView size={18} /> : null}
                        </AgreeButton>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};
const UnsavedChanges = ({ title = 'Unsaved Changes' }) => {
    return (
        <Box sx={CommonStyle.rowSpaceBetween}>
            <ICONS.InfoOutlinedIcon sx={{ fontSize: '1rem' }} />
            <Typography sx={{ px: 1, fontSize: '0.8rem' }}>{title}</Typography>
        </Box>
    );
};
const DeleteButton = ({ onClick, sx, label, disabled, ...rest }) => {
    return (
        <CommonButton
            sx={{
                fontWeight: 300,
                fontSize: 16,
                padding: 0,
                borderBottom: `1px solid ${disabled ? COLORS.gray : COLORS.error}`,
                borderRadius: 0,
                minWidth: 0,
                ...sx
            }}
            color={'error'}
            onClick={onClick}
            label={label ? label : 'Delete'}
            disabled={disabled}
            {...rest}
        />
    );
};

const ModalHeader = ({ title, onClose, style }) => {
    const HeaderBox = styled(Grid)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 24px',
        backgroundColor: COLORS.white,
        borderBottom: `1px solid ${COLORS.border}`,
        borderRadius: '0.425rem 0.425rem 0 0',
        [theme.breakpoints.down('sm')]: {
            padding: '16px 12px'
        }
    }));
    const HeaderText = styled(Typography)(({ theme }) => ({
        color: COLORS.black,
        fontWeight: '600',
        fontSize: '16px',
        wordWrap: 'break-word'
    }));
    return (
        <HeaderBox sx={{ ...style }}>
            <HeaderText id="modal-modal-title" variant="h6" component="h3">
                {title}
            </HeaderText>
            <IconButton aria-label="close" edge="end" size="small" sx={{ color: COLORS.black, p: 0 }} onClick={onClose}>
                <ICONS.Close color={COLORS.white} />
            </IconButton>
        </HeaderBox>
    );
};
export { UnsavedChanges, AlertDialog, ModalHeader, DeleteButton };
