import React, { useCallback, useEffect, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { COLORS } from '../../../utils/theme';
import AddAccountModal from './components/AddAccountModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClines } from '../../../redux/reducers/clientsSlice';
import { SERVER_URL } from '../../../utils/constants';
import { CommonTable } from '../../../components/Table';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { ViewerPermission } from '../../../components/ViewerPermission';
import CommonButton from '../../../components/CommonButton';
import useToggleState from '../../../hooks/useToggleState';
import { AlertDialog } from '../../../widgets/CommonWidgets';
import { api } from '../../../api';

const NameElementBox = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});

const AccountImage = styled('img')({
    height: 32,
    width: 32,
    borderRadius: 4
});

const AccountName = styled(Typography)({
    fontSize: '0.875rem',
    fontWeight: '500',
    color: COLORS.primary.light,
    marginLeft: 12,
    cursor: 'pointer'
});

const StyledButton = styled(CommonButton)({
    color: COLORS.black,
    fontWeight: '300',
    height: '42px',
    fontSize: '16px',
    textAlign: 'center'
});

const Accounts = () => {
    const [updateAllLoopsModalState, updateAllLoopsModalOpen, updateAllLoopsModalClose] = useToggleState({});

    const [modal, setModal] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdateAllLoopsLoading, setIsUpdateAllLoopsLoading] = useState(false);
    const { clients } = useSelector((state) => state.client);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchClines()).then(() => {
            setIsLoading(false);
        });
    }, [dispatch]);

    const NameElement = ({ item }) => {
        return (
            <NameElementBox>
                <AccountImage src={SERVER_URL + item.logo} />
                <AccountName>{item.name}</AccountName>
            </NameElementBox>
        );
    };
    const accountColumns = [
        {
            id: 'name',
            fieldName: 'name',
            label: 'Name',
            render: (row) => {
                return <NameElement item={row.rowData} />;
            }
        },
        {
            id: 'id',
            fieldName: 'id',
            label: 'Client ID',
            align: 'center'
        }
    ];

    const updateAllLoopsHandler = useCallback(async () => {
        try {
            setIsUpdateAllLoopsLoading(true);
            const { data } = await api.client.updateAllLoops();
            if (data.success) {
                enqueueSnackbar(data?.message || 'All loops updated successfully', { variant: 'success' });
                updateAllLoopsModalClose();
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } finally {
            setIsUpdateAllLoopsLoading(false);
        }
    }, [enqueueSnackbar, updateAllLoopsModalClose, setIsUpdateAllLoopsLoading]);

    const updatedAllLoops = () => {
        return (
            <ViewerPermission>
                <StyledButton
                    loading={isUpdateAllLoopsLoading}
                    onClick={() => {
                        updateAllLoopsModalOpen({
                            open: true,
                            title: 'Update All Loops',
                            description: 'Are you sure you want to update all loops?'
                        });
                    }}
                    variant="contained"
                    label={'Update All Loops'}
                    sx={{ marginRight: '1rem' }}
                    disabled={isUpdateAllLoopsLoading}
                />
            </ViewerPermission>
        );
    };

    return (
        <Box>
            <CommonHeader
                title={'Accounts'}
                buttonLabel={'Add Account'}
                searchbar={true}
                searchValue={searchValue}
                outlineInputClass={{ top: 0 }}
                handleSearch={(search) => setSearchValue(search)}
                onClick={() => setModal(true)}
                customAction={updatedAllLoops}
            />
            <Box sx={{ marginTop: '24px' }}>
                <CommonTable
                    loading={isLoading}
                    search={searchValue}
                    defaultRowsPerPage={100}
                    rowsPerPageOptions={[10, 50, 100]}
                    handleRowDetail={(data) => navigate(`/account/${data.id}`)}
                    columns={accountColumns}
                    rows={clients}
                    searchableFields={['name', 'id']}
                />
            </Box>
            {modal && <AddAccountModal open={modal} handleClose={() => setModal(false)} />}
            {updateAllLoopsModalState?.['open'] && (
                <AlertDialog
                    isOpen={updateAllLoopsModalState?.['open']}
                    isClose={updateAllLoopsModalClose}
                    handleSubmit={updateAllLoopsHandler}
                    title={updateAllLoopsModalState?.['title']}
                    description={updateAllLoopsModalState?.['description']}
                    loading={isUpdateAllLoopsLoading}
                    disabled={isUpdateAllLoopsLoading}
                    isCancel={'No'}
                />
            )}
        </Box>
    );
};

export default Accounts;
