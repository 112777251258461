import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CONTENT_DATA_TYPE, ORIENTATION, SkeletonArray } from '../../../utils/constants';
import { PAGES } from '../../../utils/urls';
import { LandscapeContentItem } from './components/LandscapeContentItem';
import { setOrientation } from '../../../redux/reducers/orientationSlice';
import { api } from '../../../api';
import { AlertDialog } from '../../../widgets/CommonWidgets';
import { useSnackbar } from 'notistack';
import useFetch from '../../../hooks/useFetch';
import { fetchAddVideoToLibrary, fetchLibrary, fetchRemoveVideoToLibrary } from '../../../redux/reducers/librarySlice';
import { checkContentStatusInLibrary, getOriantationByLayout } from '../../../utils/helpers';
import { fetchLocations } from '../../../redux/reducers/clientLocationSlice';
import BrowserHeader from '../Browse/components/BrowserHeader';

export const CreateCustom = () => {
    const navigate = useNavigate();

    const [activeOrientation, setActiveOrientation] = useState(ORIENTATION.LANDSCAPE);
    const [showDeletePopup, setDeletePopup] = useState({ isOpen: false, content: '' });

    const { user } = useSelector((state) => state.auth);
    const { librarys } = useSelector((state) => state.library);
    const { locations } = useSelector((state) => state.clientLocation);

    const theme = useTheme();
    const isInMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        let params = {
            orientation: activeOrientation
        };
        dispatch(fetchLibrary({ params }));
    }, [dispatch, activeOrientation, user?.client]);

    const {
        data: mediaContent,
        callFetch: fetchMediaContent,
        isLoading
    } = useFetch({
        initialUrl: `/get_all_media`,
        skipOnStart: true
    });

    const handleDelete = async () => {
        try {
            const { data } = await api.mediaContent.delete(showDeletePopup?.content?.id);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
                const params = {
                    client_id: user.client.id,
                    orientation: activeOrientation,
                    custom_content: 1,
                    content_type: CONTENT_DATA_TYPE.IMAGE
                };
                fetchMediaContent({ params });
                setDeletePopup({ isOpen: false, content: '' });
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const addSingleToLibrary = (item) => {
        dispatch(fetchAddVideoToLibrary([item]));
    };

    const removeSingleToLibrary = (item) => {
        dispatch(fetchRemoveVideoToLibrary([item]));
    };

    const orientationVisiblity = useMemo(() => {
        if (locations.length !== 0) {
            let isLandscapeOnly = false;
            let isPortraitOnly = false;
            isLandscapeOnly =
                locations.filter((item) => getOriantationByLayout(item?.layout) === ORIENTATION.LANDSCAPE).length === locations.length;
            isPortraitOnly =
                locations.filter((item) => getOriantationByLayout(item?.layout) === ORIENTATION.PORTRAIT).length === locations.length;
            return {
                isLandscapeOnly,
                isPortraitOnly
            };
        }
        return {
            isLandscapeOnly: false,
            isPortraitOnly: false
        };
    }, [locations]);

    const onChangeOrientation = useCallback(
        (value) => {
            if (!orientationVisiblity.isLandscapeOnly && !orientationVisiblity.isPortraitOnly) {
                setActiveOrientation(value);
            }
        },
        [orientationVisiblity.isLandscapeOnly, orientationVisiblity.isPortraitOnly]
    );

    useEffect(() => {
        if (orientationVisiblity.isPortraitOnly) {
            setActiveOrientation(ORIENTATION.PORTRAIT);
        } else {
            setActiveOrientation(ORIENTATION.LANDSCAPE);
        }
    }, [orientationVisiblity]);

    useEffect(() => {
        dispatch(fetchLocations()).then(() => {});
    }, [dispatch, user.client]);

    useEffect(() => {
        if (Boolean(mediaContent?.success)) {
            dispatch(setOrientation(activeOrientation));
        }
    }, [mediaContent, activeOrientation, dispatch]);

    useEffect(() => {
        const params = {
            orientation: activeOrientation,
            client_id: user.client.id,
            custom_content: 1,
            content_type: CONTENT_DATA_TYPE.IMAGE
        };
        fetchMediaContent({ params });
    }, [fetchMediaContent, activeOrientation, user.client.id]);

    return (
        <Box>
            <BrowserHeader
                orientation={activeOrientation}
                orientationVisiblity={orientationVisiblity}
                onChangeOrientation={onChangeOrientation}
                title={'Creator'}
                buttonLabel={!isInMobile ? 'Create New' : ''}
                onClick={() => navigate(PAGES.ADD_CUSTOM_CONTENT.url)}
                actionStyle={{ alignItems: 'center', justifyContent: 'end' }}
            />
            <Box sx={{ marginTop: '24px' }} />
            <Grid container spacing={3}>
                {isLoading
                    ? SkeletonArray.map((item) => {
                          return (
                              <Grid item key={item} xs={12} sm={6} md={4} lg={3} xxl={2}>
                                  <LandscapeContentItem loading={isLoading} orientation={activeOrientation} />
                              </Grid>
                          );
                      })
                    : mediaContent?.result?.length > 0 &&
                      mediaContent?.result?.map((content) => {
                          return (
                              <Grid item key={content?.id} xs={12} sm={6} md={4} lg={3} xxl={2}>
                                  <LandscapeContentItem
                                      onClick={() => {
                                          if (!isInMobile) {
                                              navigate(`${PAGES.EDIT_CUSTOM_CONTENT.url}/${content?.id}`);
                                          }
                                      }}
                                      content={content}
                                      orientation={activeOrientation}
                                      onDelete={() => setDeletePopup({ isOpen: true, content })}
                                      value={checkContentStatusInLibrary(content, librarys)}
                                      addSingleToLibrary={(e) => {
                                          e.stopPropagation();
                                          addSingleToLibrary(content);
                                      }}
                                      removeSingleToLibrary={(e) => {
                                          e.stopPropagation();
                                          removeSingleToLibrary(content);
                                      }}
                                  />
                              </Grid>
                          );
                      })}
            </Grid>
            {showDeletePopup?.isOpen ? (
                <AlertDialog
                    isOpen={showDeletePopup?.isOpen}
                    isClose={() => setDeletePopup({ isOpen: false, content: '' })}
                    handleSubmit={checkContentStatusInLibrary(showDeletePopup.content, librarys) ? '' : handleDelete}
                    title={'Delete Creator Content'}
                    description={
                        checkContentStatusInLibrary(showDeletePopup.content, librarys)
                            ? 'This item cannot be deleted while it is in your Lineup. Remove from your Lineup and then try again'
                            : 'Are you sure you want to delete this Creator content ?'
                    }
                    isCancel={checkContentStatusInLibrary(showDeletePopup.content, librarys) ? 'OKAY' : 'No'}
                />
            ) : null}
        </Box>
    );
};
