import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../api';
import CommonModal from '../../components/CommonModal';
import { fetchFacebookAccounts, fetchGoogleAccounts } from '../../redux/reducers/socialMediaSlice';
import { REVIEW_FILTER, SOCIAL_MEDIA } from '../../utils/constants';
import AuthorizedView from './AuthorizedView';

const EditAuthorizedContentPopup = ({ open, handleClose, data, fetchLatestData }) => {
    const [loading, setLoading] = useState({ update: false, unlink: false, cancelAddOn: false });
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { facebookAccounts, googleAccounts, socialInfo } = useSelector((state) => state.socialMedia);
    const facebookAccountList = useMemo(() => {
        return (
            facebookAccounts?.map?.((item) => {
                return {
                    name: item.name,
                    value: item.id,
                    id: item.id
                };
            }) || []
        );
    }, [facebookAccounts]);
    useEffect(() => {
        if (socialInfo) {
            if (data?.name_alias === SOCIAL_MEDIA.FACEBOOK) {
                const resData = { access_token: socialInfo?.access_token, user_id: socialInfo?.user_id };
                dispatch(fetchFacebookAccounts(resData));
            }
            if (data?.name_alias === SOCIAL_MEDIA.GOOGLE) {
                dispatch(fetchGoogleAccounts(socialInfo));
            }
        }
    }, [socialInfo, dispatch, data?.name_alias]);
    const googleAccountList = useMemo(() => {
        return (
            googleAccounts?.map?.((item) => {
                return {
                    name: item.accountName,
                    value: item.name.split('/')[item.name.split('/').length - 1],
                    id: item.name.split('/')[item.name.split('/').length - 1]
                };
            }) || []
        );
    }, [googleAccounts]);

    const authorizedData = useMemo(() => {
        let socialData = {
            name: data?.name,
            durationList: data?.duration_increments.map((item) => {
                return { name: item, value: item };
            }),
            library_status: data?.library_status,
            duration: socialInfo?.duration || data?.default_duration,
            authorization_image: socialInfo?.authorization_image || data?.authorization_image,
            link_type: socialInfo?.link_type || null
        };
        if (socialInfo) {
            socialData.id = socialInfo?.id;
            socialData.category = socialInfo?.category;
            socialData.description = socialInfo?.description;
            socialData.total_feed = socialInfo?.total_feed;
            socialData.each_feed_time = socialInfo?.each_feed_time;
            socialData.type = socialInfo?.type;
            socialData.max_posts = socialInfo?.max_posts;
        }
        if (data?.name_alias === SOCIAL_MEDIA.FACEBOOK) {
            return {
                ...socialData,
                pageList: facebookAccountList,
                page_id: socialInfo?.page_id
            };
        } else if (data?.name_alias === SOCIAL_MEDIA.GOOGLE) {
            return {
                ...socialData,
                pageList: googleAccountList,
                page_id: socialInfo?.page_id,
                reviewFilterList: REVIEW_FILTER,
                review_filter: socialInfo?.review_filter,
                location_id: socialInfo?.location_id,
                access_token: socialInfo?.access_token
            };
        } else {
            return {
                ...socialData,
                category: data?.playlist?.name,
                description: data?.description,
                url: socialInfo?.url,
                type: data?.name_alias,
                activation_status: data?.activation_status === 'active' && data?.is_authorized,
                add_on_feature: data?.add_on_feature,
                monthly_cost: data?.monthly_cost
            };
        }
    }, [data, socialInfo, facebookAccountList, googleAccountList]);
    const handleUpdate = async (params) => {
        setLoading({ ...loading, update: true });
        try {
            const { data } = await api.social.updateSocialDetails(params);
            if (data?.success) {
                fetchLatestData();
                setLoading({ ...loading, update: false });
                handleClose();
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            setLoading({ ...loading, update: false });
        }
    };

    const handleCancelAddon = async (params) => {
        setLoading({ ...loading, cancelAddOn: true });
        try {
            const { data } = await api.social.cancelAddOnApp(params);
            if (data?.success) {
                fetchLatestData();
                enqueueSnackbar(data?.message, { variant: 'success' });
                setLoading({ ...loading, cancelAddOn: false });
                handleClose();
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            setLoading({ ...loading, cancelAddOn: false });
        }
    };

    const handleUnlink = async (id) => {
        setLoading({ ...loading, unlink: true });
        try {
            const { data } = await api.social.deleteSocialAccount(id);
            if (data?.success) {
                fetchLatestData();
                setLoading({ ...loading, unlink: false });
                handleClose();
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            setLoading({ ...loading, unlink: false });
        }
    };
    return (
        <CommonModal open={open} onClose={handleClose} width={1024}>
            <Box sx={{ py: '14px' }}>
                {authorizedData && (
                    <AuthorizedView
                        loading={loading}
                        authorizedData={authorizedData}
                        onUpdateClick={handleUpdate}
                        unlinkAccount={handleUnlink}
                        onCancelAddOn={handleCancelAddon}
                    />
                )}
            </Box>
        </CommonModal>
    );
};

export default EditAuthorizedContentPopup;
