import { Box, Button, FormHelperText, styled, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { IMAGES } from '../../assets';
import CommonButton from '../../components/CommonButton';
import { CommonMultiSelect } from '../../components/CommonMultiSelect';
import { DELETE_CONFIRMATION_ADD_ON_ADD, SERVER_URL, SOCIAL_MEDIA } from '../../utils/constants';
import { isAddedInterationApp, toCapitalCase } from '../../utils/helpers';
import { COLORS } from '../../utils/theme';
import { DeleteButton, UnsavedChanges } from '../../widgets/CommonWidgets';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGoogleLocations } from '../../redux/reducers/socialMediaSlice';
import * as Yup from 'yup';
import {
    facebookAuthorizedValidation,
    googleAuthorizedValidation,
    instagramAuthorizedValidation,
    snappicAuthorizedValidation
} from '../../utils/validations';
import { CommonInput } from '../../components/CommonInput';

const LineupBox = styled(Box)({
    margin: '15px 0px'
});

const AddImage = styled('img')({
    height: 22,
    width: 22,
    objectFit: 'contain'
});

const Label = styled(Typography)({
    fontSize: 16,
    fontWeight: 'bold',
    color: COLORS.black,
    alignItems: 'center',
    display: 'flex',
    marginLeft: 8
});

const ContentBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between'
});

const MainContent = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginRight: '15px'
});

const Description = styled(Typography)({
    fontSize: '1rem',
    wordWrap: 'break-word'
});
const StyledButton = styled(CommonButton)({
    color: COLORS.black,
    fontWeight: '300',
    fontSize: '16px',
    textAlign: 'center'
});
const AuthorizationImage = styled('img')({
    width: '100%',
    aspectRatio: 1.77
});
const ControlBox = styled(Box)(({ theme }) => ({
    border: `1px solid ${COLORS.border}`,
    padding: 10,
    width: '100%',
    [theme.breakpoints.up('md')]: {
        width: '80%'
    }
}));
const StyledInput = styled(TextField)({
    color: COLORS.black,
    textDecoration: 'none',
    width: 60
});
const FieldView = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0px'
});
const FieldLabel = styled(Typography)({
    fontSize: '0.8rem',
    marginRight: 10
});
const StatusImage = styled('img')({
    height: 22,
    width: 22,
    margin: '0px 5px 0px 0px'
});

const AddOnBox = styled(Box)(({ theme }) => ({
    backgroundColor: COLORS.purple,
    borderRadius: 8,
    padding: '4px 8px',
    boxShadow: COLORS.cardShadow,
    marginRight: '5px'
}));

const AddOnText = styled(Typography)(({ theme }) => ({
    fontSize: 10,
    color: COLORS.white,
    fontWeight: 'bold',
    textAlign: 'center'
}));

const ContentAddOnBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    alignSelf: 'end',
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
    }
}));

const MonthlyCostBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'end'
});

const MonthlyCostTitle = styled(Typography)({
    fontSize: 14,
    color: COLORS.black,
    fontWeight: 500
});

const MonthlyCostDetails = styled(Typography)({
    fontSize: 14,
    color: COLORS.primary.gray
});

const StyledCancelAddOnButton = styled(CommonButton)({
    fontWeight: '300',
    fontSize: '16px',
    textAlign: 'center',
    marginLeft: '1rem',
    borderRadius: '0.425rem',
    border: `1px solid ${COLORS.primary.danger}`,
    color: COLORS.primary.danger,
    width: '200px'
});

const StyledInputURL = styled(CommonInput)({
    flex: 1
});

const AuthorizedView = ({ authorizedData, unlinkAccount, onUpdateClick, loading, onCancelAddOn }) => {
    const dispatch = useDispatch();
    const { googleLocations } = useSelector((state) => state.socialMedia);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [isAddToLibrary, setIsAddToLibrary] = useState();
    const { client } = useSelector((state) => state.auth.user);

    const { locations } = useSelector((state) => state.clientLocation);

    const appAuthorizedValidation = useMemo(() => {
        if (authorizedData.type === SOCIAL_MEDIA.FACEBOOK) return facebookAuthorizedValidation;
        else if (authorizedData.type === SOCIAL_MEDIA.GOOGLE) return googleAuthorizedValidation;
        else if (authorizedData.type === SOCIAL_MEDIA.INSTAGRAM) return instagramAuthorizedValidation;
        else if (!isAddToLibrary) return Yup.object().shape({});
        else return snappicAuthorizedValidation;
    }, [authorizedData.type, isAddToLibrary]);

    const { handleChange, handleBlur, handleSubmit, values, touched, errors, setFieldValue, setValues, setFieldError } = useFormik({
        initialValues: {
            page_id: '',
            total_feed: '10',
            each_feed_time: '5',
            max_posts: null,
            duration: '',
            add_to_library: false,
            review_filter: '',
            location_id: '',
            url: '',
            link_type: ''
        },
        validationSchema: appAuthorizedValidation,
        onSubmit: async (values) => {
            if (!isAddedInterationApp(authorizedData?.type)) {
                if (values.total_feed > values.max_posts) {
                    setFieldError('total_feed', `Value must be ${values.max_posts} or less.`);
                    return;
                }
            }
            let reqData = {
                id: authorizedData.id,
                add_to_library: values.add_to_library
            };
            if (authorizedData.duration != values.duration) {
                reqData.duration = +values.duration;
            }
            if (authorizedData.total_feed != values.total_feed) {
                reqData.total_feed = +values.total_feed;
            }
            if (authorizedData.each_feed_time != values.each_feed_time) {
                reqData.each_feed_time = +values.each_feed_time;
            }
            if (authorizedData?.type === SOCIAL_MEDIA.FACEBOOK || authorizedData?.type === SOCIAL_MEDIA.GOOGLE) {
                reqData.page_id = values.page_id;
            }
            if (authorizedData?.type === SOCIAL_MEDIA.GOOGLE) {
                reqData.review_filter = values.review_filter;
                reqData.location_id = values.location_id;
            }
            reqData.type = authorizedData?.type;
            let params;
            if (isAddedInterationApp(authorizedData?.type)) {
                params = {
                    add_to_library: values.add_to_library,
                    type: authorizedData?.type,
                    url: values.url
                };
                if (authorizedData.duration != values.duration) {
                    params.duration = +values.duration;
                }
            }

            onUpdateClick(isAddedInterationApp(authorizedData?.type) ? params : reqData);
        }
    });

    useEffect(() => {
        setIsAddToLibrary(values.add_to_library);
    }, [values.add_to_library]);

    useEffect(() => {
        if (authorizedData.type === SOCIAL_MEDIA.GOOGLE && values.page_id && authorizedData) {
            dispatch(fetchGoogleLocations({ account_id: values.page_id, access_token: authorizedData.access_token }));
        }
    }, [values.page_id, dispatch, authorizedData]);

    const locationsList = useMemo(() => {
        return (
            googleLocations?.map?.((item) => {
                return {
                    name: item.title,
                    value: item.name.split('/')[item.name.split('/').length - 1],
                    id: item.name.split('/')[item.name.split('/').length - 1]
                };
            }) || []
        );
    }, [googleLocations]);

    useEffect(() => {
        if (authorizedData) {
            setValues({
                ...values,
                page_id: authorizedData?.page_id || authorizedData?.pageList?.[0]?.value,
                total_feed: authorizedData?.total_feed?.toString() || '10',
                each_feed_time: authorizedData?.each_feed_time?.toString() || '5',
                duration: authorizedData?.duration?.toString(),
                add_to_library: authorizedData?.library_status,
                review_filter: authorizedData?.review_filter || authorizedData?.reviewFilterList?.[0]?.value,
                location_id: authorizedData?.location_id || locationsList?.[0]?.value,
                url: authorizedData?.url ? authorizedData?.url : '',
                max_posts: authorizedData?.max_posts || 0,
                link_type: authorizedData?.link_type || ''
            });
        }
    }, [authorizedData, locationsList, setValues]);

    const checkHandlechange = (data) => {
        setUnsavedChanges(true);
        handleChange(data);
    };

    const handleCancelAddOn = () => {
        let params = {
            integration_title: authorizedData?.name,
            name_alias: authorizedData?.type,
            activation_status: 'deactive',
            client_id: client.id
        };
        onCancelAddOn(params);
    };

    return (
        <>
            <ContentBox sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                <MainContent>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: { xs: 'flex-start', sm: 'center', md: 'center' },
                            flexDirection: { xs: 'column', sm: 'row', md: 'row' },
                            gap: { xs: '10px', sm: '0px', md: '0px' }
                        }}
                    >
                        <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold', marginRight: '15px' }}>{authorizedData?.name}</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {Boolean(authorizedData?.add_on_feature) && (
                                <AddOnBox>
                                    <AddOnText>ADD-ON</AddOnText>
                                </AddOnBox>
                            )}
                            {!isAddedInterationApp(authorizedData?.type) ? (
                                <>
                                    <StatusImage src={IMAGES.CheckMark} />
                                    <FieldLabel>{`Authorized`}</FieldLabel>
                                </>
                            ) : authorizedData?.activation_status ? (
                                <>
                                    <StatusImage src={IMAGES.CheckMark} />
                                    <FieldLabel>{`Activated`}</FieldLabel>
                                </>
                            ) : null}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            py: 1,
                            alignItems: { sm: 'baseline', xs: 'baseline', md: 'center', lg: 'center' },
                            flexDirection: { sm: 'column', xs: 'column', md: 'row', lg: 'row' }
                        }}
                    >
                        <FieldLabel sx={{ mr: 3 }}>{`Category: ${authorizedData?.category}`}</FieldLabel>
                        <Box
                            sx={{
                                display: 'flex',
                                width: 'auto',
                                mt: { lg: 0, md: 0, sm: 1, xs: 1 },
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Box sx={{ display: 'flex', mt: { lg: 0, md: 0, sm: 1, xs: 1 }, alignItems: 'center' }}>
                                <FieldLabel>{`Duration:`}</FieldLabel>
                                <CommonMultiSelect
                                    handleChange={checkHandlechange}
                                    handleBlur={handleBlur}
                                    options={authorizedData?.durationList}
                                    name={'duration'}
                                    error={touched.duration && errors.duration}
                                    style={{ width: 70 }}
                                    value={values.duration}
                                />
                                <FieldLabel sx={{ ml: 1 }}>{`seconds`}</FieldLabel>
                            </Box>
                            {errors.duration && (
                                <FormHelperText sx={{ display: 'flex', mt: { lg: 0, md: 0, sm: 3, xs: 3 }, alignItems: 'center' }} error>
                                    {errors.duration}
                                </FormHelperText>
                            )}
                        </Box>
                    </Box>
                    <Description>{authorizedData?.description}</Description>

                    <LineupBox>
                        {values.add_to_library ? (
                            <Button
                                onClick={() => {
                                    setUnsavedChanges(true);
                                    setFieldValue('add_to_library', false);
                                }}
                            >
                                <AddImage src={IMAGES.RemoveCircleIcon} />
                                <Label>LINEUP</Label>
                            </Button>
                        ) : (
                            <Button
                                onClick={() => {
                                    setUnsavedChanges(true);
                                    setFieldValue('add_to_library', true);
                                }}
                            >
                                <AddImage src={IMAGES.AddCircleIcon} />
                                <Label>LINEUP</Label>
                            </Button>
                        )}
                    </LineupBox>
                </MainContent>
                <Box sx={{ width: { xs: '100%', sm: '100%', md: '50%' } }}>
                    <AuthorizationImage src={SERVER_URL + authorizedData?.authorization_image} alt={'Authorization image'} />
                </Box>
            </ContentBox>
            {!isAddedInterationApp(authorizedData?.type) ? (
                <React.Fragment>
                    <FieldLabel>{'Controls:'}</FieldLabel>
                    <ControlBox>
                        {(authorizedData.type === SOCIAL_MEDIA.FACEBOOK || authorizedData?.type === SOCIAL_MEDIA.GOOGLE) && (
                            <FieldView>
                                <FieldLabel>
                                    {authorizedData.type === SOCIAL_MEDIA.FACEBOOK ? 'Facebook Page:' : 'Business Profile:'}
                                </FieldLabel>
                                <CommonMultiSelect
                                    handleChange={checkHandlechange}
                                    handleBlur={handleBlur}
                                    options={authorizedData?.pageList}
                                    name={'page_id'}
                                    error={touched.page_id && errors.page_id}
                                    style={{ width: { lg: '65%', md: '65%', sm: '65%', xs: '100%' } }}
                                    value={values.page_id}
                                />
                            </FieldView>
                        )}

                        <FieldView>
                            <FieldLabel>{`Number of ${
                                authorizedData?.type === SOCIAL_MEDIA.GOOGLE ? 'reviews' : 'post'
                            } in feed:`}</FieldLabel>
                            <StyledInput
                                error={Boolean(errors.total_feed)}
                                onChange={checkHandlechange}
                                handleBlur={handleBlur}
                                name={'total_feed'}
                                value={values?.total_feed}
                            />
                            <FieldLabel sx={{ ml: 1 }}>{`(max ${values.max_posts || 0})`}</FieldLabel>
                        </FieldView>
                        {errors.total_feed && <FormHelperText error>{errors.total_feed}</FormHelperText>}

                        <FieldView>
                            <FieldLabel>{`Show each ${authorizedData?.type === SOCIAL_MEDIA.GOOGLE ? 'reviews' : 'post'} for:`}</FieldLabel>
                            <StyledInput
                                error={Boolean(errors.each_feed_time)}
                                handleBlur={handleBlur}
                                onChange={checkHandlechange}
                                name={'each_feed_time'}
                                value={values?.each_feed_time}
                            />
                            <FieldLabel sx={{ ml: 1 }}>{'seconds (max 15)'}</FieldLabel>
                        </FieldView>
                        {errors.each_feed_time && <FormHelperText error>{errors.each_feed_time}</FormHelperText>}

                        {authorizedData?.type === SOCIAL_MEDIA.GOOGLE && (
                            <>
                                <FieldView>
                                    <FieldLabel>{'Review Filter:'}</FieldLabel>
                                    <CommonMultiSelect
                                        handleChange={checkHandlechange}
                                        handleBlur={handleBlur}
                                        options={authorizedData?.reviewFilterList}
                                        name={'review_filter'}
                                        error={touched.review_filter && errors.review_filter}
                                        style={{ width: { lg: '65%', md: '65%', sm: '65%', xs: '100%' } }}
                                        value={values.review_filter}
                                    />
                                </FieldView>
                                <FieldView sx={{ mt: 1 }}>
                                    <FieldLabel>{'Locations:'}</FieldLabel>
                                    <CommonMultiSelect
                                        handleChange={checkHandlechange}
                                        handleBlur={handleBlur}
                                        options={locationsList}
                                        name={'location_id'}
                                        error={touched.location_id && errors.location_id}
                                        style={{ width: { lg: '100%', md: '100%', sm: '100%', xs: '100%' } }}
                                        value={values.location_id}
                                    />
                                </FieldView>
                            </>
                        )}
                    </ControlBox>
                </React.Fragment>
            ) : authorizedData?.link_type === 'user-defined' ? (
                <React.Fragment>
                    {isAddedInterationApp(authorizedData?.type) && (
                        <React.Fragment>
                            <FieldLabel>{'Controls:'}</FieldLabel>
                            <ControlBox>
                                {isAddedInterationApp(authorizedData?.type) && (
                                    <FieldView>
                                        <FieldLabel>{`URL:`}</FieldLabel>
                                        <StyledInputURL
                                            onChange={checkHandlechange}
                                            handleBlur={handleBlur}
                                            error={touched.url && errors.url}
                                            sx={{ width: '90%' }}
                                            name={'url'}
                                            value={values?.url}
                                        />
                                    </FieldView>
                                )}
                            </ControlBox>
                        </React.Fragment>
                    )}
                </React.Fragment>
            ) : null}
            <ContentBox
                sx={{
                    alignItems: { xs: 'flex-start', sm: 'flex-start', md: 'center' },
                    flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                    gap: { xs: '10px', sm: '10px', md: '0px' },
                    marginTop: '20px'
                }}
            >
                <StyledButton onClick={handleSubmit} variant="contained" label={'Update'} loading={loading.update} />
                {unsavedChanges && <UnsavedChanges />}
                {!isAddedInterationApp(authorizedData?.type) ? (
                    <DeleteButton
                        sx={{ fontSize: '12px', alignSelf: 'end' }}
                        onClick={() => unlinkAccount(authorizedData?.id)}
                        size={'small'}
                        label={`Unlink this ${toCapitalCase(authorizedData?.type)} Account`}
                        loading={loading.unlink}
                    />
                ) : Boolean(authorizedData?.add_on_feature) ? (
                    <ContentAddOnBox sx={{ gap: { xs: '10px', sm: '10px', md: '0px' } }}>
                        <MonthlyCostBox>
                            <MonthlyCostTitle> {`+$${authorizedData?.monthly_cost}/month/location`}</MonthlyCostTitle>
                            <MonthlyCostDetails>{`( ${locations?.length} locations X $${authorizedData?.monthly_cost} = $${
                                authorizedData?.monthly_cost * locations?.length
                            }/month )`}</MonthlyCostDetails>
                        </MonthlyCostBox>
                        <StyledCancelAddOnButton
                            onClick={handleCancelAddOn}
                            variant="outlined"
                            color="error"
                            label={'Cancel Add-On'}
                            loading={loading.cancelAddOn}
                            confirmation={DELETE_CONFIRMATION_ADD_ON_ADD}
                        />
                    </ContentAddOnBox>
                ) : null}
            </ContentBox>
        </>
    );
};

export default AuthorizedView;
