import { useEffect } from 'react';
import {
    styled,
    Typography,
    Box,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    IconButton,
    Checkbox,
    FormHelperText
} from '@mui/material';
import { useFormik } from 'formik';
import { ICONS } from '../../../../assets';
import CommonButton from '../../../../components/CommonButton';
import { COLORS } from '../../../../utils/theme';
import CustomDateTimePicker from '../../../../components/CustomDateTimePicker';
import { LOOP_SCHEDULE_EVENT_TYPE, LOOP_SCHEDULE_TYPE, SCHEDULE_RECURRENCE_PATTERN_TYPE } from '../../../../utils/constants';
import { combineDateAndTime, enumerateDaysBetweenDates, formateToDateTime } from '../../../../utils/helpers';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoopFormDataByKey } from '../../../../redux/reducers/loopSlice';
import * as Yup from 'yup';

const MainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    position: 'relative'
});
const LoopNameText = styled(Typography)({
    fontSize: 16,
    color: COLORS.black
});

const CustomeContentBox = styled(Box)({
    display: 'flex',
    width: '100%'
});

const CustomRadioGroup = styled(RadioGroup)({
    flexDirection: 'row'
});

const CustomLabel = styled(Typography)({
    fontSize: 12
});
const LabelText = styled(Typography)({
    fontSize: 16,
    marginRight: 20
});

const BackIconButton = styled(IconButton)({
    position: 'absolute',
    left: 0,
    top: -10
});
const DateTimeView = styled(Box)({
    width: '100%'
});
const ContentBox = styled(Box)({
    margin: '15px 0px',
    display: 'flex',
    alignItems: 'center'
});
const Space = styled(Box)({
    marginLeft: 10
});
const StyledButton = styled(CommonButton)({
    marginTop: 20,
    color: COLORS.black,
    fontWeight: 'normal'
});
const daysArray = [
    { selected: false, day: 'S', id: 7 },
    { selected: false, day: 'M', id: 1 },
    { selected: false, day: 'T', id: 2 },
    { selected: false, day: 'W', id: 3 },
    { selected: false, day: 'T', id: 4 },
    { selected: false, day: 'F', id: 5 },
    { selected: false, day: 'S', id: 6 }
];
export const CustomSchedule = ({ handleCloseCustomeMenu }) => {
    const dispatch = useDispatch();
    const { loopFormData } = useSelector((state) => state.loop);
    const schedulingValidation = Yup.object().shape({
        startDate: Yup.date().required('Start Date is required'),
        endDate: Yup.date().required('End Date is required'),
        startTime: Yup.date()
            .required('Start Time is required')
            .test('startTime', 'Start time can not be less than current time', (value) => {
                const now = moment();
                if (moment(values.startDate).isSame(now, 'day')) {
                    const startDateTime = combineDateAndTime(values.startDate, values.startTime);
                    return moment().isBefore(moment(startDateTime));
                } else {
                    return true;
                }
            }),
        endTime: Yup.date()
            .required('End Time is required')
            .test('endTime', 'The minimum time for a Custom Schedule must be 1 hour', (value) => {
                const now = moment();
                if (moment(values.startDate).isSame(now, 'day')) {
                    const startDateTime = combineDateAndTime(values.startDate, values.startTime);
                    const endDateTime = combineDateAndTime(values.endDate, values.endTime);
                    return moment(endDateTime).isSameOrAfter(moment(startDateTime).add(1, 'hours'));
                } else {
                    return true;
                }
            })
    });
    const { values, handleSubmit, setFieldValue, errors, setValues } = useFormik({
        initialValues: {
            eventType: LOOP_SCHEDULE_EVENT_TYPE.ONE_TIME,
            startDate: new Date(),
            endDate: new Date(),
            startTime: moment().add('hours', 1)._d,
            endTime: moment().add('hours', 2)._d,
            recurrencePattern: '',
            days: [],
            starTimeError: '',
            endTimeError: ''
        },
        validationSchema: schedulingValidation,
        onSubmit: async (values) => {
            let loop_schedules = [];
            if (values.eventType === LOOP_SCHEDULE_EVENT_TYPE.ONE_TIME) {
                const startDateTime = moment(
                    moment(values.startDate).format('YYYY-MM-DD') + ' ' + moment(values.startTime).format('HH:mm:ss')
                )._d;
                const endDateTime = moment(
                    moment(values.endDate).format('YYYY-MM-DD') + ' ' + moment(values.endTime).format('HH:mm:ss')
                )._d;
                let schedule = {
                    startDateTime: formateToDateTime(startDateTime),
                    endDateTime: formateToDateTime(endDateTime)
                };
                loop_schedules.push(schedule);
            } else {
                const allDays = enumerateDaysBetweenDates(values.startDate, values.endDate);
                if (values.recurrencePattern === SCHEDULE_RECURRENCE_PATTERN_TYPE.DAILY) {
                    const loop_schedules_data = allDays.flatMap((item, index) => {
                        const currentDay = moment(item).format('YYYY-MM-DD');
                        const nextDay = moment(item).add(1, 'day').format('YYYY-MM-DD');

                        const startTime = moment(values.startTime, 'HH:mm:ss');
                        const endTime = moment(values.endTime, 'HH:mm:ss');

                        const startTimeOnly = startTime.format('HH:mm');
                        const endTimeOnly = endTime.format('HH:mm');
                        const isEndBeforeStartTime = moment(endTimeOnly, 'HH:mm').isBefore(moment(startTimeOnly, 'HH:mm'));

                        const schedules = [];

                        if (!isEndBeforeStartTime) {
                            schedules.push({
                                startDateTime: formateToDateTime(moment(`${currentDay} ${startTime.format('HH:mm:ss')}`).toDate()),
                                endDateTime: formateToDateTime(moment(`${currentDay} ${endTime.format('HH:mm:ss')}`).toDate())
                            });
                        } else {
                            const isEndBeforeStart = moment(values.endTime, 'HH:mm:ss').isBefore(moment(values.startTime, 'HH:mm:ss'));
                            if (index === 0) {
                                schedules.push({
                                    startDateTime: formateToDateTime(
                                        moment(`${currentDay} ${moment(values.startTime).format('HH:mm:ss')}`)._d
                                    ),
                                    endDateTime: formateToDateTime(moment(`${currentDay} 24:00:00`)._d)
                                });

                                if (isEndBeforeStart) {
                                    schedules.push({
                                        startDateTime: formateToDateTime(moment(`${nextDay} 00:00:00`)._d),
                                        endDateTime: formateToDateTime(moment(`${nextDay} ${moment(values.endTime).format('HH:mm:ss')}`)._d)
                                    });
                                }
                            } else if (index === allDays.length - 1) {
                                schedules.push({
                                    startDateTime: formateToDateTime(moment(`${currentDay} 00:00:00`)._d),
                                    endDateTime: formateToDateTime(moment(`${currentDay} ${moment(values.endTime).format('HH:mm:ss')}`)._d)
                                });
                            } else {
                                schedules.push({
                                    startDateTime: formateToDateTime(moment(`${currentDay} 00:00:00`)._d),
                                    endDateTime: formateToDateTime(moment(`${currentDay} ${moment(values.endTime).format('HH:mm:ss')}`)._d)
                                });

                                schedules.push({
                                    startDateTime: formateToDateTime(
                                        moment(`${currentDay} ${moment(values.startTime).format('HH:mm:ss')}`)._d
                                    ),
                                    endDateTime: formateToDateTime(moment(`${currentDay} 24:00:00`)._d)
                                });
                            }
                        }

                        return schedules;
                    });

                    let uniqueSchedules = loop_schedules_data.filter((schedule, index, self) => {
                        const current = JSON.stringify(schedule);
                        return index === self.findIndex((item) => JSON.stringify(item) === current);
                    });
                    loop_schedules = uniqueSchedules;
                } else {
                    const allDays = enumerateDaysBetweenDates(values.startDate, values.endDate);
                    const filteredDays = allDays.filter((date) => values.days.includes(moment(date).day()));

                    let loop_schedules_data = filteredDays.flatMap((item) => {
                        const currentDay = moment(item).format('YYYY-MM-DD');
                        const nextDay = moment(item).add(1, 'day').format('YYYY-MM-DD');

                        const startTime = moment(values.startTime, 'HH:mm:ss');
                        const endTime = moment(values.endTime, 'HH:mm:ss');

                        const startTimeOnly = startTime.format('HH:mm');
                        const endTimeOnly = endTime.format('HH:mm');
                        const isEndBeforeStartTime = moment(endTimeOnly, 'HH:mm').isBefore(moment(startTimeOnly, 'HH:mm'));

                        const schedules = [];

                        if (!isEndBeforeStartTime) {
                            schedules.push({
                                startDateTime: formateToDateTime(moment(`${currentDay} ${startTime.format('HH:mm:ss')}`)._d),
                                endDateTime: formateToDateTime(moment(`${currentDay} ${endTime.format('HH:mm:ss')}`)._d)
                            });
                        } else {
                            schedules.push({
                                startDateTime: formateToDateTime(moment(`${currentDay} ${moment(values.startTime).format('HH:mm:ss')}`)._d),
                                endDateTime: formateToDateTime(moment(`${currentDay} 24:00:00`)._d)
                            });

                            schedules.push({
                                startDateTime: formateToDateTime(moment(`${nextDay} 00:00:00`)._d),
                                endDateTime: formateToDateTime(moment(`${nextDay} ${moment(values.endTime).format('HH:mm:ss')}`)._d)
                            });
                        }

                        return schedules;
                    });

                    loop_schedules = loop_schedules_data;
                }
            }

            let scheduleData = {
                days: values.days,
                startDateTime: combineDateAndTime(values.startDate, values.startTime),
                endDateTime: combineDateAndTime(values.endDate, values.endTime),
                eventType: values.eventType,
                recurrencePattern: values.recurrencePattern,
                loop_schedules: loop_schedules?.map((item) => {
                    return {
                        start_date_time: item.startDateTime,
                        end_date_time: item.endDateTime
                    };
                }),
                type: LOOP_SCHEDULE_TYPE.CUSTOM
            };
            dispatch(updateLoopFormDataByKey({ key: 'schedule', value: [scheduleData] }));
            dispatch(updateLoopFormDataByKey({ key: 'type', value: LOOP_SCHEDULE_TYPE.CUSTOM }));
            handleCloseCustomeMenu([scheduleData]);
        }
    });

    useEffect(() => {
        if (loopFormData?.schedule?.[0]?.type === LOOP_SCHEDULE_TYPE.CUSTOM) {
            setValues({
                eventType: loopFormData?.schedule?.[0]?.eventType,
                recurrencePattern: loopFormData?.schedule?.[0]?.recurrencePattern,
                days: loopFormData?.schedule?.[0]?.days,
                startDate: moment(loopFormData?.schedule?.[0]?.startDateTime)._d,
                startTime: moment(loopFormData?.schedule?.[0]?.startDateTime)._d,
                endDate: moment(loopFormData?.schedule?.[0]?.endDateTime)._d,
                endTime: moment(loopFormData?.schedule?.[0]?.endDateTime)._d
            });
        }
    }, [loopFormData?.schedule, setValues]);
    const handledDaysArray = (item) => {
        let days = [...values.days];
        const index = days.findIndex((e) => e === item.id);
        if (index === -1) {
            days.push(item.id);
        } else {
            days.splice(index, 1);
        }

        setFieldValue('days', days);
    };
    const renderDays = () => {
        return (
            <ContentBox>
                {daysArray?.map((item) => {
                    return (
                        <>
                            <Checkbox
                                key={item.id}
                                sx={{ padding: { xs: '4px', md: '10px' } }}
                                name="days"
                                onChange={(e) => handledDaysArray(item)}
                                checked={values.days.findIndex((e) => e === item.id) !== -1}
                            />
                            <CustomLabel>{item.day}</CustomLabel>
                        </>
                    );
                })}
            </ContentBox>
        );
    };
    return (
        <MainBox>
            <BackIconButton onClick={handleCloseCustomeMenu}>
                <ICONS.Back />
            </BackIconButton>
            <LoopNameText>Custom Schedule</LoopNameText>
            <CustomeContentBox sx={{ mt: 2 }}>
                <FormControl sx={{ mt: 2 }}>
                    <FormLabel id="demo-radio-buttons-group-label">Event Type</FormLabel>
                    <CustomRadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={LOOP_SCHEDULE_EVENT_TYPE.ONE_TIME}
                        name="radio-buttons-group"
                    >
                        <FormControlLabel
                            value={LOOP_SCHEDULE_EVENT_TYPE.ONE_TIME}
                            checked={values.eventType === LOOP_SCHEDULE_EVENT_TYPE.ONE_TIME}
                            control={<Radio />}
                            onChange={() => setFieldValue('eventType', LOOP_SCHEDULE_EVENT_TYPE.ONE_TIME)}
                            label={<CustomLabel> One-Time Event</CustomLabel>}
                        />
                        <FormControlLabel
                            value={LOOP_SCHEDULE_EVENT_TYPE.RECURNING}
                            checked={values.eventType === LOOP_SCHEDULE_EVENT_TYPE.RECURNING}
                            control={<Radio />}
                            onChange={() => {
                                setFieldValue('eventType', LOOP_SCHEDULE_EVENT_TYPE.RECURNING);
                                setFieldValue('recurrencePattern', SCHEDULE_RECURRENCE_PATTERN_TYPE.DAILY);
                            }}
                            label={<CustomLabel>Recurring Event</CustomLabel>}
                        />
                    </CustomRadioGroup>
                </FormControl>
            </CustomeContentBox>
            {values.eventType === LOOP_SCHEDULE_EVENT_TYPE.RECURNING && (
                <FormControl sx={{ width: '100%' }}>
                    <FormLabel id="demo-radio-buttons-group-label">Recurrence Pattern</FormLabel>
                    <CustomRadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={LOOP_SCHEDULE_EVENT_TYPE.ONE_TIME}
                        name="radio-buttons-group"
                    >
                        <FormControlLabel
                            value={SCHEDULE_RECURRENCE_PATTERN_TYPE.DAILY}
                            checked={values.recurrencePattern === SCHEDULE_RECURRENCE_PATTERN_TYPE.DAILY}
                            control={<Radio />}
                            onChange={() => setFieldValue('recurrencePattern', SCHEDULE_RECURRENCE_PATTERN_TYPE.DAILY)}
                            label={<CustomLabel> Daily</CustomLabel>}
                        />
                        <FormControlLabel
                            value={SCHEDULE_RECURRENCE_PATTERN_TYPE.SPECIFICDAYS}
                            checked={values.recurrencePattern === SCHEDULE_RECURRENCE_PATTERN_TYPE.SPECIFICDAYS}
                            control={<Radio />}
                            onChange={() => setFieldValue('recurrencePattern', SCHEDULE_RECURRENCE_PATTERN_TYPE.SPECIFICDAYS)}
                            label={<CustomLabel>Specific Days</CustomLabel>}
                        />
                    </CustomRadioGroup>
                </FormControl>
            )}
            <DateTimeView>
                {values.recurrencePattern === SCHEDULE_RECURRENCE_PATTERN_TYPE.SPECIFICDAYS &&
                    values.eventType === LOOP_SCHEDULE_EVENT_TYPE.RECURNING &&
                    renderDays()}
                <ContentBox>
                    <LabelText>{values.eventType === LOOP_SCHEDULE_EVENT_TYPE.ONE_TIME ? 'Start' : 'Start Date'}</LabelText>
                    <CustomDateTimePicker
                        type="date"
                        options={{
                            dateFormat: 'D d-m-Y',
                            minDate: 'today'
                        }}
                        name={'startDate'}
                        value={values.startDate}
                        error={errors.startDate}
                        onChange={([date]) => {
                            setFieldValue('startDate', date);
                        }}
                    />
                    <Space />
                    {values.eventType === LOOP_SCHEDULE_EVENT_TYPE.ONE_TIME && (
                        <CustomDateTimePicker
                            type="time"
                            value={values.startTime}
                            onChange={([time]) => {
                                setFieldValue('startTime', time);
                            }}
                            name={'startTime'}
                            error={errors.startTime}
                        />
                    )}
                </ContentBox>
                {errors.startDate && <FormHelperText error>{errors.startDate}</FormHelperText>}
                {errors.startTime && <FormHelperText error>{errors.startTime}</FormHelperText>}
                <ContentBox>
                    <LabelText sx={{ mr: 3 }}>{values.eventType === LOOP_SCHEDULE_EVENT_TYPE.ONE_TIME ? 'End' : 'End Date'}</LabelText>
                    <CustomDateTimePicker
                        type="date"
                        options={{
                            dateFormat: 'D d-m-Y',
                            minDate: values.startDate
                        }}
                        value={values.endDate}
                        onChange={([date]) => {
                            setFieldValue('endDate', date);
                        }}
                        name={'endDate'}
                    />
                    <Space />
                    {values.eventType === LOOP_SCHEDULE_EVENT_TYPE.ONE_TIME && (
                        <CustomDateTimePicker
                            type="time"
                            value={values.endTime}
                            onChange={([time]) => {
                                setFieldValue('endTime', time);
                            }}
                            name={'endTime'}
                            error={errors.endTime}
                        />
                    )}
                </ContentBox>
                {errors.endDate && <FormHelperText error>{errors.endDate}</FormHelperText>}
                {errors.endTime && <FormHelperText error>{errors.endTime}</FormHelperText>}
                {values.eventType === LOOP_SCHEDULE_EVENT_TYPE.RECURNING && (
                    <ContentBox>
                        <LabelText>Time</LabelText>
                        <CustomDateTimePicker
                            type="time"
                            value={values.startTime}
                            onChange={([time]) => {
                                setFieldValue('startTime', time);
                            }}
                        />
                        <LabelText sx={{ mx: 1 }}>-</LabelText>
                        <CustomDateTimePicker
                            type="time"
                            value={values.endTime}
                            onChange={([time]) => {
                                setFieldValue('endTime', time);
                            }}
                        />
                    </ContentBox>
                )}
            </DateTimeView>
            <StyledButton label={'Save Custom Schedule'} onClick={handleSubmit} variant="outlined" />
        </MainBox>
    );
};
